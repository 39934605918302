import * as React from "react"
import "reset-css"
import { Layout } from "../components/layout"
import { GlobalCss } from "../theme"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import { Feature } from "../components/feature/feature"
import { getImage } from "gatsby-plugin-image"
import { UsefulInformationCard } from "../components/useful-information/useful-information-card"
import styled from "styled-components"
import { mediaQuery } from "../theme"
import { Container } from "../theme"

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  ${mediaQuery("xl")} {
    grid-template-columns: 1fr 1fr;
  }
  ${mediaQuery("lg")} {
    grid-template-columns: 1fr;
  }
`

const SuggestionsPage = ({ data }) => {
  const {
    allWpPost: { nodes },
  } = data
  return (
    <>
      <Seo post={data.wpPage} />
      <GlobalCss />
      <Layout>
        <Feature
          heading="Pasiūlymai"
          image={data.wpPage.ACF_feature.image.localFile}
          paragraph={data.wpPage.ACF_feature.text}
        />
        <Container>
          <CardsContainer>
            {nodes.reverse().map((card, index) => (
              <UsefulInformationCard
                heading={card.title}
                date={card.date}
                image={getImage(card.featuredImage.node.localFile)}
                content={card.content}
                slug={card.slug}
                id={`news-card-${index + 1}`}
              />
            ))}
          </CardsContainer>
        </Container>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allWpPost {
      nodes {
        content
        slug
        title
        date(formatString: "YYYY MMMM DD", locale: "lt")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "pasiulymai" }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      ACF_feature {
        text
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default SuggestionsPage
